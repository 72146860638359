<template>
    <div class="flex mx-5 my-5">
        <SideBar btnText="+ 新增最新消息" @clickBtn="addNews" />
        <div class="flex-1 pb-5">
            <div class="relative pb-5 ml-3 bg-white rounded-lg shadow-lg">
                <LoadingComponent :isLoading="loading" />
                <div class="overflow-x-auto">
                    <table class="myTable">
                        <thead>
                            <tr>
                                <th>內容</th>
                                <th>使用期間</th>
                                <th v-permission="['update', 'delete']">
                                    操作
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in datas"
                                :key="index"
                                class="text-center transition duration-200"
                            >
                                <td class="show-ellipsis">{{ item.text }}</td>
                                <td>
                                    <span
                                        v-if="item.start_date || item.end_date"
                                    >
                                        {{ item.start_date | formatDate }} -
                                        {{ item.end_date | formatDate }}
                                    </span>
                                    <span v-else> 不限使用期間 </span>
                                </td>
                                <td v-permission="['update', 'delete']">
                                    <div class="flex justify-center">
                                        <div
                                            class="mr-10 cursor-pointer"
                                            @click="goEdit(item.id)"
                                        >
                                            <i class="far fa-edit"></i>
                                        </div>
                                        <div
                                            class="text-red-500 cursor-pointer"
                                            @click="
                                                showDialog = true;
                                                deleteNewId = item.id;
                                            "
                                        >
                                            <i class="fas fa-trash-alt"></i>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- 確認是否取消預訂彈窗 -->
        <my-dialog
            :showDialog="showDialog"
            :customClass="'top-1/4 z-[70]'"
            :customBlackBgClass="'z-[60]'"
            @onCloseDialog="closeDialog"
        >
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <p>確認刪除嗎？</p>
                <div class="flex justify-center mt-5">
                    <button
                        :disabled="loading"
                        class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                        @click.prevent="closeDialog"
                    >
                        關閉
                    </button>
                    <button
                        v-loading="loading"
                        :disabled="loading"
                        class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                        @click.prevent="deleteNews()"
                    >
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>


<script >
import { ref, reactive, computed, onActivated, onMounted, watch, getCurrentInstance, defineExpose, onBeforeUnmount, nextTick } from "@vue/composition-api";

// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
import SideBar from "../components/SideBar.vue";
import { useRoute, useRouter } from "vue2-helpers/vue-router";
// 彈窗
import myDialog from "@/components/Dialog.vue";
// 取得全域 this 方法 ex: this.$api, this.$message
const __sfc_main = {
  name: "MemberNews"
};
__sfc_main.setup = (__props, __ctx) => {
  const {
    proxy
  } = getCurrentInstance();
  const router = useRouter();
  const route = useRoute();
  const loading = ref(false);
  const datas = ref([]);

  // 判斷是否顯示刪除彈窗
  const showDialog = ref(false);
  function closeDialog() {
    showDialog.value = false;
  }
  // 刪除 New id
  const deleteNewId = ref(null);
  // 刪除
  async function deleteNews() {
    loading.value = true;
    try {
      await proxy.$api.DeleteMemberNewsApi(deleteNewId.value);
      proxy.$message({
        type: "success",
        message: "刪除成功"
      });
      closeDialog();
      await getList();
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "刪除失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  // 新增
  function addNews() {
    router.push({
      name: "add_member_news"
    });
  }
  // 編輯
  function goEdit(id) {
    router.push({
      name: `edit_member_news`,
      params: {
        id
      }
    });
  }
  /**
   * 取的列表
   */
  async function getList() {
    loading.value = true;
    try {
      const {
        data
      } = await proxy.$api.GetMemberNewsListApi();
      datas.value = data.announcements;
    } catch (err) {
      proxy.$message({
        type: "error",
        message: "取得資料失敗"
      });
    } finally {
      loading.value = false;
    }
  }
  onMounted(async () => {
    await getList();
  });
  return {
    loading,
    datas,
    showDialog,
    closeDialog,
    deleteNewId,
    deleteNews,
    addNews,
    goEdit
  };
};
__sfc_main.components = Object.assign({
  SideBar,
  LoadingComponent,
  myDialog
}, __sfc_main.components);
export default __sfc_main;
</script>

<style lang="scss" scoped>
.myTable {
    width: 100%;
    tbody {
        tr .show-ellipsis {
            max-width: 100px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
